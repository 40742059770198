<template>
  <div>
    <div class="audit" :style="{'background-color': item.status == 1 ? '#f5f7fa':item.status == 2 ? '#f0f9eb':item.status == 3 ? '#fef0f0':''}">
      <div v-for="(auditorItem,index) in item.auditorList" :key="index">
        <div :class="auditorItem.auditResult == 2 ?'success':(auditorItem.auditResult == 3 ? 'danger':'info')">
          <el-popover :width="240" trigger="hover" placement="top">
            <div style="text-align: center" v-if="auditorItem.auditResult == 2">已审核</div>
            <div style="text-align: center" v-if="auditorItem.auditResult == 3">已拒绝</div>
            <div style="text-align: center" v-if="auditorItem.auditResult == null || auditorItem.auditResult == 1">待审核</div>
            <div style="margin-top: 10px" v-if="auditorItem.auditorTime!=null">审核时间：{{auditorItem.auditorTime}}</div>
            <div style="margin-top: 10px" v-if="auditorItem.remark!=null">备注：{{auditorItem.remark}}</div>
            <template #reference>
              <div style="font-size: 12px;font-weight: bold">&nbsp;{{auditorItem.coachName}}</div>
            </template>
          </el-popover>
          <el-icon v-if="item.auditorList.length > index+1" style="font-size: 24px;margin: 0 50px;color:#a5a5a5">
            <ArrowRight/>
          </el-icon>
        </div>
      </div>
     <span style="font-size: 12px;font-weight: bold;  height: 26px;line-height: 26px;margin-left: 20px">  {{ item.endTime }}</span>
    </div>
    <div class="turn-detail">
      <div style="display: flex;align-items: center;">
        <h3>申请详情 - {{item.approvalProcessId}}</h3>
        <el-tooltip
            class="box-item"
            effect="dark"
            content="申请时候填写的全部信息，"
            placement="top"
        >
          <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
            <Warning/>
          </el-icon>
        </el-tooltip>
      </div>
        <div style="margin: 10px 0 10px 10px">
          <div style="display:flex;">
            <div style="width: 180px">申请老师：{{ item.coachName }}</div>
            <div style="width: 240px">申请时间：{{ item.createTime }}</div>
            <div v-if="item.money ==null">备注：{{ item.remark }}</div>
          </div>
          <div style="display:flex;margin-top: 4px">
            <div v-if="item.money !=null" style="width: 180px">缴费金额：{{ item.money }} ￥</div>
            <div v-if="item.collectionTime !=null" style="width: 240px">缴费日期：{{ item.collectionTime }}</div>
            <div v-if="item.transaction !=null" style="width: 280px">缴费单号：{{ item.transaction }}</div>
            <div v-if="item.money !=null">备注：{{ item.remark }}</div>
          </div>
        </div>
        <div style="display:flex;">
          <div class="apply-order">
            <div style="display: flex;align-items: center;width: 100%;justify-content: center;margin-bottom: 16px">
              <div class="apply-order-title">旧</div>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="申请时候订单的分摊、状态等信息，不会因为修改订单、上课、失效等因素而改变！"
                  placement="top"
              >
                <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </div>
            <div style="display:flex;justify-content: space-around">
              <div class="main" style="height: 76px;width: 47%;">
                <div class="type typecolor" style="background-color: #CDA571;color: white">{{ item.beforeState }}</div>
                <div class="mainCardName"> </div>
                <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
                  <div>分摊剩余：{{ item.apportionMoney }} ￥</div>
                </div>
              </div>
              <div v-if="item.beforeSendState != null" class="vice" style="height: 76px;width: 47%;">
                <view class="type typecolor" style="background-color: #58a3e9;color: white">{{ item.beforeSendState }}</view>
              </div>
              <div v-else style="width: 47%;"></div>
            </div>
          </div>
          <div class="apply-order">
            <div style="display: flex;align-items: center;width: 100%;justify-content: center;margin-bottom: 16px">
              <div class="apply-order-title">新</div>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="申请时候新订单的课程类型、课程数量等信息，不会因为修改订单、上课、失效等因素而改变！"
                  placement="top"
              >
                <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </div>
            <div style="display:flex;justify-content: space-around">
              <div class="main" style="height: 76px;width: 47%;">
                <div class="mainCardName">{{ item.newOrderCardName }}</div>
                <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
                  <div>课时数量:{{ item.newOrderRemaining }} / 次、小时、期</div>
                </div>
              </div>
              <div v-if="item.newOrderSendCardName != null" class="vice" style="height: 76px;width: 47%;">
                <div class="viceCardName">{{ item.newOrderSendCardName }}</div>
                <div style="font-size: 12px;padding:0px 26px;color: #456087;margin-top: 2px;">
                  <div>课时数量:{{ item.newOrderSendRemaining }} / 次、小时、期</div>
                </div>
              </div>
              <div v-else style="width: 47%;"></div>
            </div>
          </div>
        </div>
    </div>
    <div class="turn-detail" style="margin-top: 10px">
      <div style="display: flex;align-items: center;padding-bottom:10px">
        <h3>订单详情</h3>
        <el-tooltip
            class="box-item"
            effect="dark"
            content="申请订单和新订单的信息（实时更新：申请后如：上课、修改等操作，会和申请时存在差异！）"
            placement="top"
        >
          <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
            <Warning/>
          </el-icon>
        </el-tooltip>
      </div>
      <div style="display: flex;">
        <div class="apply-order">
          <div style="display: flex;align-items: center;padding-bottom:16px;justify-content: center">
            <div class="apply-order-title">旧 - {{item.orderInfo.oid}}</div>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="旧订单的信息（实时更新：申请后如：上课、修改等操作，会和申请时存在差异！）"
                placement="top"
            >
              <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
                <Warning/>
              </el-icon>
            </el-tooltip>
          </div>
          <div style="display: flex;justify-content: flex-start">
            <div class="main">
              <view class="type typecolor" style="background-color: #CDA571;color: white">{{item.orderInfo.state}}</view>
              <div class="mainCardName">{{ item.orderInfo.cardAttributeRule.name}}</div>
              <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
                <div>姓名:{{item.orderInfo.studentName}} - {{item.orderInfo.studentId}}</div>
                <div style="padding: 2px 0">
                  开始时间:{{item.orderInfo.startTime == null ? "待使用": item.orderInfo.startTime}}
                </div>
                <div>
                  结束时间:{{item.orderInfo.endTime == null ? "待使用": item.orderInfo.endTime}}
                </div>

                <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 90%;">
                  <div>
                    <div>{{ item.orderInfo.remaining }}</div>
                    <div>剩余课时</div>
                  </div>
                  <div>
                    <div>{{ item.orderInfo.ouse }}</div>
                    <div>已用课时</div>
                  </div>
                  <div>
                    <div>{{ item.orderInfo.orderInfo.buyNumber }}</div>
                    <div>总购课时</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.orderInfo.sendorder!=null" class="vice">
              <view class="type typecolor" style="background-color: #58a3e9;color: white">{{item.orderInfo.sendorder.state}}</view>
              <div class="viceCardName">{{ item.orderInfo.sendorder.cardAttributeRule.name }}</div>
              <div style="font-size: 12px;padding:0px 26px;color: #456087;margin-top: 2px;">
                <div>姓名:{{item.orderInfo.studentName}}</div>
                <div style="padding: 4px 0">
                  开始时间:{{item.orderInfo.sendorder.startTime == null ? "待使用": item.orderInfo.sendorder.startTime}}
                </div>
                <div>
                  结束时间:{{item.orderInfo.sendorder.endTime == null ? "待使用": item.orderInfo.sendorder.endTime}}
                </div>
                <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                  <div>
                    <div>{{ item.orderInfo.sendorder.remaining }}</div>
                    <div>剩余课时</div>
                  </div>
                  <div>
                    <div>{{ item.orderInfo.sendorder.suse }}</div>
                    <div>已用课时</div>
                  </div>
                  <div>
                    <div>{{ item.orderInfo.orderInfo.sendNumber }}</div>
                    <div>总赠课时</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="apply-order">
          <div style="display: flex;align-items: center;padding-bottom:16px;justify-content: center">
            <div class="apply-order-title">新 - {{item.newOderInfo!=null ? item.newOderInfo.oid :''}}</div>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="新订单的全部信息（实时更新：上课、修改等操作，会和申请时存在差异！）"
                placement="top"
            >
              <el-icon style="margin-left: 4px;font-size: 14px;font-weight: normal">
                <Warning/>
              </el-icon>
            </el-tooltip>
          </div>
          <div v-if="item.newOderInfo!=null" style="display: flex;justify-content: flex-start">
            <div class="main">
              <view class="type typecolor" style="background-color: #CDA571;color: white">{{item.newOderInfo.state}}</view>
              <div class="mainCardName">{{ item.newOderInfo.cardAttributeRule.name}}</div>
              <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
                <div style="padding: 2px 0">
                  开始时间:{{item.newOderInfo.startTime == null ? "待使用": item.newOderInfo.startTime}}
                </div>
                <div>
                  结束时间:{{item.newOderInfo.endTime == null ? "待使用": item.newOderInfo.endTime}}
                </div>

                <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 90%;">
                  <div>
                    <div>{{ item.newOderInfo.remaining }}</div>
                    <div>剩余课时</div>
                  </div>
                  <div>
                    <div>{{ item.newOderInfo.ouse }}</div>
                    <div>已用课时</div>
                  </div>
                  <div>
                    <div>{{ item.newOderInfo.orderInfo.buyNumber }}</div>
                    <div>总购课时</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.newOderInfo.sendorder!=null" class="vice">
              <view class="type typecolor" style="background-color: #58a3e9;color: white">{{item.newOderInfo.sendorder.state}}</view>
              <div class="viceCardName">{{ item.newOderInfo.sendorder.cardAttributeRule.name }}</div>
              <div style="font-size: 12px;padding:0px 26px;color: #456087;margin-top: 2px;">
                <div style="padding: 4px 0">
                  开始时间:{{item.newOderInfo.sendorder.startTime == null ? "待使用": item.newOderInfo.sendorder.startTime}}
                </div>
                <div>
                  结束时间:{{item.newOderInfo.sendorder.endTime == null ? "待使用": item.newOderInfo.sendorder.endTime}}
                </div>
                <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                  <div>
                    <div>{{ item.newOderInfo.sendorder.remaining }}</div>
                    <div>剩余课时</div>
                  </div>
                  <div>
                    <div>{{ item.newOderInfo.sendorder.suse }}</div>
                    <div>已用课时</div>
                  </div>
                  <div>
                    <div>{{ item.newOderInfo.orderInfo.sendNumber }}</div>
                    <div>总赠课时</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty :description="item.status == 1 ? '审核中...':item.status == 2 ? '数据走丢啦！':item.status == 3 ? '审核未通过！':'未知申请！'" image-size="90" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TurnDetail",
  props: {
    item: Object,
  },
  created() {
    console.log(this.item)
  }
}
</script>

<style scoped>

.audit{
  display:flex;
  padding: 8px 16px;
  margin-top: -20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.turn-detail{
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 16px;
  font-size: 13px;
}
.apply-order{
  width: 50%;
  background-color: white;
  margin: 0 10px;
  border-radius: 10px;
  padding: 10px;
}
.apply-order-title{
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}
.el-table__fixed-body-wrapper {
  z-index: auto !important;
}
.danger{
  color: #F56C6C;
  display:flex;
  height: 26px;
  line-height: 26px
}
.success{
  color: #67c23a;
  display:flex;
  height: 26px;
  line-height: 26px
}
.info{
  color: #909399;
  display:flex;
  height: 26px;
  line-height: 26px
}
.card {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 8px;
}
.mainCardName{
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #bb9c6e, #000000);
  font-style: italic;-webkit-background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viceCardName{
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #81B7EB, #000000);
  font-style: italic;-webkit-background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.type{
  line-height: 26px;
  font-style: italic;
  border-radius: 0px 15px 0px 15px;
  font-weight: bold;
  height: 26px;
  width: 66px;
  font-size: 12px;
  text-align: center;
  float:right;
}
.main{
  background: -webkit-linear-gradient(left,#F5DCB5,#e3c28f);
  height: 154px;
  border-radius: 15px;
  width: 266px
}
.vice{
  background: -webkit-linear-gradient(left,#C9E3F8,#81B7EB);
  height: 154px;
  border-radius: 15px;
  width: 266px;
  margin-left: 10px;
}
</style>