<template>
  <div style="padding: 20px">
  <div class="handle-box">
      <select-area @areaListId="getAreaListId"/>
      <el-select v-model="query.type" placeholder="订单类型" class="handle-select mr10">
        <el-option key="0" label="全部" value=""></el-option>
        <el-option key="1" label="向上转卡" :value="1"></el-option>
        <el-option key="2" label="向下转卡" :value="2"></el-option>
        <el-option key="3" label="同等转卡" :value="3"></el-option>
        <el-option key="4" label="转给他人" :value="4"></el-option>
      </el-select>
      <el-select v-model="query.status" placeholder="状态" class="handle-select mr10">
        <el-option key="0" label="全部" value=""></el-option>
        <el-option key="2" label="待审核" value="1"></el-option>
        <el-option key="2" label="同意" value="2"></el-option>
        <el-option key="3" label="拒绝" value="3"></el-option>
      </el-select>
      <el-input v-model="query.coachName" placeholder="教练姓名" class="handle-input mr10"/>
      <el-input v-model="query.studentName" placeholder="学员姓名" class="handle-input mr10"/>
      <el-date-picker
          class="handle-input"
          v-model="time"
          type="daterange"
          style="margin:0 20px"
          range-separator="至"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
         >
      </el-date-picker>
      <el-date-picker
          class="handle-input"
          v-model="completeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="完成开始日期"
          end-placeholder="完成结束日期"
         >
      </el-date-picker>
      <el-button type="primary" @click="getData" style="margin-left: 20px" icon="Search">搜索</el-button>
      <el-button type="info" icon="Search" @click="reset">重置</el-button>
      <el-popconfirm
          title="确定导出吗？"
          @confirm="exportExcel"
      >
        <template #reference>
          <el-button type="primary" icon="Download">导出</el-button>
        </template>
      </el-popconfirm>
      <el-dialog
    v-model="dialogVisible"
    title="温馨提示"
    width="30%"
    :before-close="handleClose"
  >
    <span>请点击右上角下载中心查看</span>
    <template #footer>
      <span class="dialog-footer">   
        <el-button type="primary" @click="dialogVisible = false"
          >OK</el-button
        >
      </span>
    </template>
  </el-dialog>
    </div>
    <el-table
        :data="data"
        border
        height="660px"
        v-loading="tableLoading"
        class="table el-table__cell"
        ref="multipleTable"
        type=index
        :row-style="{height: '0'}"
        :cell-style="{padding: '0'}"
        header-cell-class-name="table-header"
        :row-class-name="tableRowClassName"
        style="text-align: center;"
    >
      <el-table-column prop="coachName" width="120" label="申请人"></el-table-column>
      <el-table-column width="120" label="订单类型">
        <template v-slot="{row}">
          <el-tag type=warning>
                {{row.type==1?'向上转卡':row.type==2?'向下转卡':row.type==3?'同等转卡':row.type==4?'转给他人':'未知类型'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column  width="170" label="缴费金额">
        <template v-slot="{row}">
            <div v-if="row.type == 2 ? false : true">
              <span v-if="row.money!==null">缴费金额：{{row.money }}￥</span>
              <span v-if="row.money==null" style="color:#C0C4CC">暂无信息</span>       
            </div>
        </template>
      </el-table-column>
      <el-table-column prop="apportionMoney" width="170" label="转出金额">
        <template v-slot="{row}">
          <span v-if="row.apportionMoney!==null">转出金额：{{ row.apportionMoney }}￥</span>
          <span v-if="row.apportionMoney==null" style="color:#C0C4CC">暂无信息</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="270">
        <template v-slot="{row}">
          <div class="audit" :style="{'background-color': row.status == 1 ? '#f5f7fa':row.status == 2 ? '#f0f9eb':row.status == 3 ? '#fef0f0':''}">
            <div v-for="(item,index) in row.auditorList" :key="index">
              <div :class="item.auditResult == 2 ?'success':(item.auditResult == 3 ? 'danger':'info')">
                <div style="font-size: 12px;width:50px">&nbsp;{{item.coachName}}</div>
                <el-icon v-if="row.auditorList.length > index+1" style="font-size: 20px;margin: 0 10px;color:#a5a5a5">
                  <ArrowRight/>
                </el-icon>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="170" label="所属城市" show-overflow-tooltip="true">
        <template #default="row">
            {{ row.row.regionName}}-{{ row.row.campusName }}
        </template>
      </el-table-column>
      <el-table-column
          label="图片"
          width="100">
        <template v-slot="products">
          <el-image class="code"
              :src="products.row.picture[0]"
              :preview-src-list="products.row.picture"
          >
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" prop="createTime" width="170"/>
      <el-table-column prop="endTime" label="完成时间" width="170"/>
      <el-table-column prop="remark" show-overflow-tooltip="true" label="备注" width="180"/>
      <el-table-column prop="denyExplain" show-overflow-tooltip="true" label="驳回原因" width="180"/>
      <el-table-column prop="money" fixed="right" width="110" label="操作" >
        <template v-slot="{row}">
          <el-button size="small" style="width: 14px;" type="text" @click="selectDetail(row)">详情</el-button>
          <el-button size="small" style="width: 14px;" type="text">编辑</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
  <el-dialog v-model="detailDialog" width="1230px" draggable
             :title="detailItem.type==1?'向上转卡':detailItem.type==2?'向下转卡':detailItem.type==3?'同等转卡':detailItem.type==4?'转给他人':'未知类型'" >
    <turn-detail style="height: 600px;" :item="detailItem"/>
  </el-dialog>
</template>

<script>
import {turnExcel,getTurn} from "@/api/refundOrTurnRecord";
import {selectOrderTurnInfoById, financeSend} from "@/api/orders";
import {selectAreaByExpress} from "@/api/area";
import selectArea from "../../../components/SelectArea";
import TurnDetail from "./components/TurnDetail";
// import WANG from '../../wangEditor'
export default {
  components: {selectArea,TurnDetail},
  props:{
    selectArea
  },
  name: "turn",
  data() {
    return {
      dialogVisible: false,
      tableLoading: false,
      data: [],
      orders:{},
      regionList: [],
      campusList: [],
      time: [],
      completeTime: [],
      pageTotal: 100,
      turnInfo: {},
      Upload: false,
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [],
      query: {
        areaList:[],
        status: null,
        studentName: '',
        coachName: '',
        startDate: null,
        endDate: null,
        completeStartDate: null,
        completeEndDate: null,
        type:null,
        pageIndex: 1,
        pageSize: 10,
      },
      detailDialog:false,
      detailItem: {}
    }
  },
  created() {
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
   this.getData()
  },
  methods: {
    getAreaListId(val){
      this.query.areaList = val;
    },
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    handleRemove() {
      this.fileList = []
    },
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        // this.uploadState.Switch = true;
        financeSend(form).then(value => {
          this.Upload = false;
          let blob = new Blob([value], {
            type: "application/vnd.ms-excel"
          });
          console.log(blob);
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a'); //我们用模拟q标签点击事件
          const fname = '订单统计信息'; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute('download', fname);
          document.body.appendChild(link);
          /**
           * 在这期间，我们可以做自己界面的反应逻辑
           **/
          link.click(); //点击
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(URL); // 释放掉blob对象
        })
      }

    },
    getData() {
      if (this.time.length !== 0) {
        this.query.startDate = this.dateFormat(this.time[0]);
        this.query.endDate = this.dateFormat(this.time[1]);
      }
      if (this.completeTime.length !== 0) {
        this.query.completeStartDate = this.dateFormat(this.completeTime[0]);
        this.query.completeEndDate = this.dateFormat(this.completeTime[1]);
      }
      let query = this.query;
      // if (this.query.areaList!=null && this.query.areaList!=""){
      //   query.areaList = JSON.stringify(this.query.areaList).replace('[','').replace(']','');
      // }
     getTurn(query).then(res => {
        if (res.code == 200) {
          this.data = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取数据失败");
        }
      })
    },
    exportExcel() {
      this.dialogVisible  = true
      turnExcel(this.query).then(res => {
        console.log(res);

        // let blob = new Blob([res], {
        //   type: "application/vnd.ms-excel"
        // });
        // console.log(blob);
        // let objectUrl = URL.createObjectURL(blob);
        // console.log(objectUrl);
        // const link = document.createElement('a'); //我们用模拟q标签点击事件
        // const fname = '订单统计信息'; //下载文件的名字
        // link.href = objectUrl;
        // link.setAttribute('download', fname);
        // document.body.appendChild(link);
        // /**
        //  * 在这期间，我们可以做自己界面的反应逻辑
        //  **/
        // link.click(); //点击
        // document.body.removeChild(link); // 下载完成移除元素
        // window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    async selectDetail(item) {
      let index = item.index;
      if (item.newOrderId!=null){
        await selectOrderTurnInfoById(item.newOrderId).then(val => {
          if(val.code==200){
            this.data[index].newOderInfo = val.data.data.oldOrders;
          }else{
            this.$message.error("信息查询失败");
          }
        })
      }
      await selectOrderTurnInfoById(item.orderId).then(val => {
         if (val.data.code == 200) {
           this.data[index].orderInfo = val.data.data.oldOrders;
         } else {
           this.$message.error("信息查询失败");
         }
       })
      this.detailItem = this.data[index];
      this.detailDialog = true;
   },
    // 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses
      }
    },
    reset() {
      this.time = [],
          this.completeTime = [],
          this.query.regionId = null,
          this.query.campusId = null,
          this.query.status = null,
          this.query.student = null,
          this.query.coach = '',
          this.query.startDate = null,
          this.query.endDate = null,
          this.query.completeStartDate = null,
          this.query.completeEndDate = null,
          this.query.type=null,
          this.query.pageIndex = 1,
          this.query.pageSize = 10,
          this.getData()
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    }
  }
}
</script>
<style scoped>
.equipment {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;

}

.credentialsImg {
  width: 60px;
  height: 60px;
  border-radius: 12px
}

.mr10 {
  margin-right: 10px;
}

.handle-box {
  margin-bottom: 20px;
  display: flex;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.redItem {
  color: #99A9C5;
}

.card {
  width: 370px;
  margin: 0 80px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
.sendCard{ 
  padding: 30px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
.student {
   
  text-align: left;
  padding: 6px 0;
  display: flex;
  justify-content: space-between;
}

.time {
  text-align: left;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sug_info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-table__cell {
  position: static !important;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
.audit{
  display:flex;
  padding: 8px;
  border-radius: 10px;
  overflow-x: auto;
  margin: 8px 0;
}
.audit::-webkit-scrollbar {
  width: 0;
  height: 2px;
}
.danger{
  color: #F56C6C;
  display:flex;
  height: 26px;
  line-height: 26px
}
.success{
  color: #67c23a;
  display:flex;
  height: 26px;
  line-height: 26px
}
.info{
  color: #7b7b7b;
  display:flex;
  height: 26px;
  line-height: 26px
}
</style>